.application-review-header {
  background: #fff;
  padding: 24px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  margin: 24px 24px 0;
  border-radius: 4px;
  position: sticky;
  top: 24px;
  z-index: 9;
  transition: all 0.2s ease-in;

  &.shrink {
    top: 0;
    margin: 24px 8px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.6);
  }

  .certification-details {
    display: flex;

    .certification-info {
      text-align: left;
      padding-right: 24px;

      .certification-info-header {
        display: flex;
        align-items: center;

        h1 {
          font-size: 24px;
          font-weight: bold;
          margin: 0;
          margin-right: 8px;
        }
      }

      .progress {
        .progress-bar {
          background: #eeeeee;
          height: 14px;
          width: 500px;
          border-radius: 3px;

          .completed {
            background: #58a6ff;
            height: 100%;
            border-radius: 3px;
            transition: all 0.2s ease-in;
          }
        }

        span {
          color: #b2b2b2;
          font-size: 12px;
        }
      }
    }
  }

  .certification-submission {
    margin-left: 20px;
    height: 100%;
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 2px solid #eee;

    > strong {
      font-size: 12px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;