@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.collapse-item {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 3px;

  &.danger {
    border-color: #af3947;

    header {
      border-color: #af3947 !important;
    }

    .title {
      color: #af3947;
    }
  }

  &.success {
    border-color: #38af76;

    header {
      border-color: #38af76 !important;
    }

    .title {
      color: #38af76;
    }
  }

  & + & {
    margin-top: 16px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 16px 24px;

    &.visible {
      border-bottom: 1px solid #eee;
    }

    .info {
      strong {
        display: block;
        font-size: 16px;
      }

      span {
        opacity: 0.6;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .extra-actions {
        margin-right: 16px;
        margin-left: 16px;
      }

      .arrow {
        font-size: 18px;
        transition: all 0.2s ease-in;

        &.visible {
          transform: rotate(180deg);
        }
      }
    }
  }

  .body {
    animation: fadeIn 1s;
    display: none;
    padding: 24px;

    &.visible {
      display: block;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;