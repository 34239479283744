.buyMoreButton {
  margin-left: auto;
  margin-bottom: -40px;
  z-index: 9;
}

.buyMoreButtonContainer {
  display: flex;

  @media screen and (max-width: 396px) {
    display: block;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;