.settings-tile {
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  display: flex;

  &:hover {
    background: #eee;
  }

  .tile-info {
    margin-left: 8px;

    strong {
      display: block;
      font-size: 18px;
    }

    span {
      opacity: 0.6;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;