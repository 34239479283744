/* Steps */
.paymentSteps {
  margin-left: 20px !important;
}
.paymentSteps .ant-steps-item {
  min-width: 130px !important;
}
.paymentSteps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}
.paymentSteps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}

/* NEW */
.purchaseModalInputBoderless {
  border: 0 !important;
  background-color: transparent !important;
  color: lighten(#000000, 30%) !important;
}
/* Overview step */
.purchaseModalOverviewTotalContainer {
  margin-top: 4px;
}
.purchaseModalOverviewTotalContainerRow {
  margin: 0 !important;
  height: 44px !important;
  margin-top: -14px !important;
}
.purchaseModalOverviewTotal {
  font-weight: 600 !important;
  font-size: 15px;
}
.purchaseModalOverviewTableHeader {
  background-color: lighten(#000000, 90%);
  opacity: 0.9;
  border-radius: 3;
  font-weight: 500;
  margin-bottom: 4px;
  padding-left: 10px;
}
/* Buttons */
.purchaseModalConfirmationButton {
  margin-left: 15px;
}
.purchaseModalCancelButton {
  margin-right: 15px;
  background-color: lighten(gray, 30%) !important;
}
.subdividers {
  font-size: 14px !important;
}
/* Voucher */
.fixedInputButton_Voucher {
  margin-left: -6px;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* Schedule modal */
.sessionSelectionModal {
  .ant-modal-body {
    width: 1200px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;