.application-card {
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .optional-badge {
    position: absolute;
    background: #af3947;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 4px;
    border: 2px solid #fff;
    right: -10px;
    top: -10px;
  }

  &.rejected {
    border: 2px solid #af3947;

    .info .title {
      color: #af3947 !important;
    }
  }

  &.approved {
    border: 2px solid #39af76;

    .info .title {
      color: #39af76 !important;
    }
  }

  &.historic {
    border: 2px solid #39af76;

    .info .title {
      color: #39af76 !important;
    }
  }

  &.uploaded {
    border: 2px solid #448ef7;

    .info .title {
      color: #448ef7 !important;
    }
  }

  .info {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      strong {
        font-size: 20px;
        display: flex;
        align-items: center;
        line-height: 22px;

        span {
          margin-right: 8px;
        }
      }

      .actions {
        display: flex;
        align-items: center;

        button {
          background: transparent;
          border: none;
          border-radius: none;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    .description {
      font-size: 12px;
      line-height: 16px;
      color: #8e8e8e;

      p {
        margin-top: 16px;
      }
    }
  }

  .card-body {
    margin-top: 24px;
    flex: 1;
    display: flex;

    button.upload {
      border: 2px solid #af3947;
      color: #af3947;
      background: transparent;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease-in;

      span {
        margin-left: 8px;
      }

      &:hover {
        background: #af3947;
        color: #fff;
      }

      &[disabled] {
        background: #8e8e8e;
        border-color: #8e8e8e;
        color: #fff !important;
        cursor: default;
      }
    }

    button.rejected {
      background: #af3947;
      border: none;
      color: #fff !important;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease-in;

      span {
        margin-left: 8px;
      }

      &:hover {
        background: darken(#af3947, 3%);
        color: #fff;
      }

      &[disabled] {
        background: #8e8e8e;
        border-color: #8e8e8e;
        color: #fff !important;
        cursor: default;
      }
    }

    .infos-list {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        small {
          display: block;
          opacity: 0.7;
        }

        & + li {
          margin-top: 8px;
        }
      }

      .card-file-name {
        display: block;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .application-file-actions-buttons {
        button {
          border: 0;
          color: #fff;
          border-radius: 3px;
          cursor: pointer;

          & + button {
            margin-left: 8px;
          }

          &.rejected {
            background: #af3947;
          }

          &.approved {
            background: #39af76;
          }

          &.historic {
            background: #39af76;
          }

          &.uploaded {
            background: #448ef7;
          }
        }
      }
    }
  }
}

.rejected-tile {
  background: #af3947;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;

  header {
    strong {
      font-size: 20px;
    }
  }

  .body {
    margin-top: 16px;

    small {
      opacity: 0.7;
    }

    p {
      font-size: 16px;
    }

    .card-file-name {
      display: block;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .application-file-actions-buttons {
      button {
        background: transparent;
        border: none;
        font-size: 18px;
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.2s ease-in;
        border-radius: 4px;

        &:hover {
          opacity: 1;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;