.sessionResultModal .ant-modal-body {
  min-width: 50vw;
}

// Info
.shortTimeInput .ant-time-picker {
  width: 100%;
}

// Questions
.questionsForm-sectionTitle {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.questionsForm-sectionTotal {
  font-size: 16px;
  font-weight: 600;
  margin-left: 40px;
}
.questionsForm-subsectionTitle {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin-left: 10px;
}
.questionsForm-subsectionTotal {
  font-size: 14px;
  font-weight: 600;
  margin-left: 40px;
}
.questionsForm-questionTitle {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin-top: 4px;
}
.questionsForm-grandTotal {
  font-size: 18px;
  font-weight: 600;
  margin-left: 40px;
  color: black;
}

.questionsForm-inputWrapper {
  .questionsForm-gradeInput {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .postfix {
    position: absolute;
    width: 120px;
    top: 6px;
    height: 32px;
    right: 0px;

    padding: 4px 11px;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: black;
    font-weight: 400;
  }
}
// Popover
.questionsForm-totalPopover {
}
.questionsForm-totalPopoverContent {
  height: 70px;
}
.questionsForm-totalPopoverIcon,
.questionsForm-totalPopoverFeedbackIcon {
  margin-left: 10px;
}
.questionsForm-totalPopoverFeedbackIcon.pass {
  svg {
    color: green;
  }
}
.questionsForm-totalPopoverFeedbackIcon.fail {
  svg {
    color: red;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;