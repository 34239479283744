.application-requirements-wrapper {
  padding: 24px;

  .application-requirements-section {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    background: #fff;
    border-radius: 3px;

    & + & {
      margin-top: 24px;
    }

    header {
      padding: 24px;

      h2 {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
      }

      p {
        opacity: 0.8;
      }
    }

    .progress-container {
      span {
        font-size: 12px;
        margin-left: 24px;
        opacity: 0.6;
      }

      .progress-bar {
        width: 100%;
        background: #f0f0f0;
        height: 5px;

        .completed {
          background: #58a6ff;
          height: 100%;
          transition: all 0.2s ease-in;
        }
      }
    }

    .body {
      padding: 24px;

      .collapse-body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.large {
          .data-item {
            small {
              font-size: 14px !important;
              font-weight: bold;
            }

            .value {
              font-size: 16px !important;
            }
          }
        }

        .collapse-data-section {
          padding-right: 32px;
        }

        .collapse-files-section {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;

          .file-card {
            border: 1px solid #eee;
            border-radius: 3px;
            width: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & + .file-card {
              margin-left: 24px;
            }

            .file-details {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 16px;

              .file-name {
                text-overflow: ellipsis;
                width: 96px;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                margin-top: 12px;
              }
            }

            footer {
              border-top: 1px solid #eee;
              padding: 0 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.application-item-review-actions {
  display: flex;
  align-items: center;

  button + button {
    margin-left: 16px;
  }

  button.approve {
    background: #39af76;
    border-color: #39af76 !important;
    color: #fff !important;
  }

  button.remove {
    background: #58a6ff;
    border-color: #58a6ff !important;
    color: #fff !important;
  }

  button.is-active {
    box-shadow: 0 5px 18px -4px rgba(0, 0, 0, 0.6) !important;
    transform: scale(1.2);
    z-index: 8;
  }

  button.is-available {
    opacity: 0.5;

    &:hover {
      opacity: 1 !important;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;