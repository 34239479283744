.contactUsModal {
}
.contactUsModal .ant-modal-body {
  min-width: 50vw;
}
.contactUsForm {
}
.contactUsSubmitButton {
  margin-left: 20px;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;