.adminSearchSessionsTable {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 40px;
}
.adminSearchSessionsTable .ant-table-tbody {
  background-color: white !important;
}
.adminSearchSessionsTable .ant-table-tbody tr {
  border-radius: 0 !important;
}
.adminSearchSessionsTable .ant-table-thead th {
  border-radius: 0 !important;
}
.adminSearchSessionsTable tr.ant-table-row-warning {
  color: red !important;
}
//
.sessionSearchTotalRow {
  margin-top: -30px;
  margin-bottom: 30px;
}
.sessionSearchTotalLabel {
  font-size: 14px !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;