@import './CommonColors.less';

body {
  padding: 0;
  // min-width: 700px !important;
}

section {
  background-color: #ececec !important;
}

/* #headers stuff */
.appHeader .ant-row {
  height: 100% !important;
}

.main-content {
  overflow: auto !important;
}

.appHeaderFullscreen,
.appHeader,
header.appHeader {
  box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  height: 90px !important;
  background: at(@shades-of-grey, light) !important;
  border-bottom: 1.3px solid at(@shades-of-grey, main);
}

div.ant-col.menu-icon {
  background: at(@shades-of-grey, light) !important;
}

div.ant-col.menu-icon i {
  font-size: 22px !important;
}

.ant-layout-header {
  @media screen and (max-width: 650px) {
    padding: 0 20px !important;
  }
}

.bccsaHeaderLogo {
  @media screen and (max-width: 650px) {
    display: none;
  }
}

.headerMiddleLogo {
  @media screen and (max-width: 650px) {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 0 !important;
    }
  }
}

.mobileHeaderMenu {
  display: none;

  @media screen and (max-width: 650px) {
    display: block;
  }
}

.headingLogo {
  float: left;
}

.headingLogo {
  margin-left: 65px;
}
.headingLogo.authorized {
  margin-left: -100px;
}

.headingTitleLogo {
  height: 80px;
  margin-top: 3px;
  margin-left: 102px;
}
.headingTitleLogoFixed {
  height: 80px;
  margin-top: 3px;
  margin-left: 160px;
}

/* User Badge */
.idmBadgeContainer {
  background-color: black !important;
  border-color: black !important;
  margin-right: 20px !important;
  background-color: transparent !important;
  border-color: transparent !important;

  .idmBadgeUserIcon {
    font-size: 16px !important;
    color: white !important;
    color: black !important;
    background-color: at(@shades-of-grey, dark) !important;
    box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: at(@shades-of-grey, darker) !important;
    }

    .idmBadgeUserText {
      color: at(@shades-of-white, main) !important;
    }
  }
}

// Layout
section.container-layout {
  height: 100vh !important;
}

/* Navigation */
.ant-layout-sider-light {
  z-index: 1000 !important;
}

ul.sideNavigation {
  padding-top: 20px !important;
  border-right: 1.3px solid at(@shades-of-grey, light) !important;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2) !important;
  li {
    .ant-menu-title-content {
      color: #444444 !important;
      a {
        color: #444444 !important;
      }
      .anticon {
        margin-right: 4px;
      }
    }
  }
  .orgSwitcher {
    background: rgb(233, 233, 233) !important;
  }
}

/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
.pageHeaderNoHeight {
  width: 100px;
}
.pageHeaderNoHeight .ant-page-header-heading {
  position: absolute;
  z-index: 99 !important;
}
.pageContent {
  padding: 10px 30px 50px 30px;
  min-height: 100%;
}
.pageContentSubsection {
  padding: 10px 50px 10px 50px;
}
/* Page - fullscreen */
.pageContentFullScreen {
  padding: 30px;
  height: 100%;
  background-color: #ececec !important;
}

/* Alert */
.alertController {
  z-index: 9999;
}

/* Default logo */
.bwLogo {
  height: 50px;
  margin-bottom: 20px;
}

/* Footer */
.appFooter {
  height: 30px !important;
  background: #ececec !important;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 40px;
  padding-top: 20px;
  padding-right: 20px;
  z-index: 20;
  width: 360px;
  border-radius: 6px;
  right: 0 !important;
  bottom: 0;
  position: absolute;

  .appFooterColumn {
    margin-right: 10px !important;
  }

  .appFooterLogo {
    height: 24px;
    width: 24px;
    margin: 0 15px;
  }

  @media screen and (max-width: 960px) {
    padding-bottom: 30px;
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 10px;
    width: auto;
  }
}

// Commons styles - this should go to a separated file one day
.fixedInputCheckbox {
  margin-left: 34px !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;