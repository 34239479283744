.application-section {
  margin-bottom: 48px;
  background: #fff !important;

  header {
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .application-section-header {
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
    }

    span:not(.ant-btn > *) {
      font-size: 14px;
      color: #8e8e8e;
    }
  }
}

.tile-column {
  @media (max-width: 991px) {
    padding-bottom: 24px;
  }
}

.uploadModal {
  .ant-modal-body {
    min-width: 850px;
    max-width: 900px;

    h1 {
      font-weight: bold;
      font-size: 24px;
    }
  }

  &.noRightSection {
    .ant-modal-body {
      min-width: 550px;
      max-width: 900px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;