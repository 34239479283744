.dashboardLicensesCol {
  margin-top: -15px;
}
.dashboardLicensesButton {
  color: gray !important;
}
.dashboardLicensesButton .anticon {
  font-size: 18px !important;
}
.dashboardLicensesButton:hover {
  color: #af3947 !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;