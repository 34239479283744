.certification-user-header {
  background: #fff;
  padding: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 !important;

  .back-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid #eee;
    padding: 0 !important;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-right: 48px;

    > strong {
      font-size: 18px;
    }

    span,
    small {
      color: #8e8e8e;
    }
  }

  .waive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 2px solid #eee;
  }

  .certification-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: right;
    flex: 1;
    border-left: 2px solid #eeeeee;

    h1 {
      font-size: 28px;
      font-weight: bold;
      margin: 0;
      color: #333;
    }
  }

  .user-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.2s ease-in;
      padding: 8px;
      border-radius: 4px;

      & + a {
        margin-left: 8px;
      }

      &:hover {
        background: #eee;
        color: #af3947 !important;
      }
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;