.employerInputContainer {
  padding-left: 10px;
  border: 1px solid #dedede;
  height: 34px;
  max-width: 500px;
  background-color: white;
  .swapButton {
    margin-left: 10px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;