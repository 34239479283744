.tabContainer {
  padding: 20px 30px 0px 30px !important;
  width: 100%;
  height: 100%;
}
.tabView {
  width: 100%;
  height: 100%;
  max-width: 1400px !important;
  margin: 0 auto 0 auto !important;
  //Tabs bar container
  .ant-tabs-bar {
    margin: 0;
    margin-bottom: 1px;
    border: none !important;
  }

  //
  .ant-tabs-nav {
    display: none !important;
  }
  // Content
  // .ant-tabs-tabpane, .ant-card, .ant-card-bar, .ant-tab-active {
  //   border-color: transparent !important;
  // }
  .ant-tabs-content {
    height: 100%;
  }
}
.commonTabContent {
  background-color: white;
  width: 100%;
  min-height: 100% !important;
  padding: 20px 20px 0 20px;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;