.approvalModal {
  top: 15% !important;
}
.approvalModal .ant-modal-body {
  min-width: 850px;
  max-width: 900px;
}
.confirmButton {
  margin-left: 10px;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;