.sessionsFilters {
  margin-bottom: 10px;
  .filterItem {
    display: flex;
    align-items: center;

    strong {
      margin-right: 5px;
    }

    & + .filterItem {
      margin-left: 35px;
    }

    .innerFilterItem {
      background-color: white !important;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0px 10px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;