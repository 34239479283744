@import './CommonColors.less';

h1,
h2,
h3 {
  color: at(@shades-of-red, dark) !important;
}

// Created classes
button.primary.solid {
  margin-left: 8px;
  background: at(@shades-of-red, main) !important;
  border-color: at(@shades-of-red, main) !important;

  &:hover {
    opacity: 0.8;
  }
}

button.secondary.bordered {
  border-color: at(@shades-of-grey, main) !important;
  color: at(@shades-of-grey, dark) !important;
}

// Ant Design CSS element changes
.ant-modal-header {
  background: linear-gradient(to bottom, at(@shades-of-grey, lighter), at(@shades-of-grey, main)) !important;

  .ant-modal-title {
    color: at(@shades-of-grey, darker) !important;
  }
}

div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-navigation
  > div.ant-steps-item.ant-steps-item-process.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  background: at(@shades-of-grey, dark) !important;
  border: 1px solid at(@shades-of-grey, dark) !important;
}

div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-navigation
  > div.ant-steps-item.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  border-color: at(@shades-of-grey, dark) !important;
}

div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-navigation
  > div.ant-steps-item.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-icon
  > span.ant-steps-icon
  > i.anticon.anticon-check.ant-steps-finish-icon
  > svg {
  color: at(@shades-of-grey, dark) !important;
}

.ant-steps-navigation .ant-steps-item::before {
  background: at(@shades-of-grey, darker) !important;
}

div.ant-steps-item-title {
  color: at(@shades-of-grey, dark) !important;
}

div.steps-action {
  display: flex !important;
  justify-content: flex-end !important;
}

.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: at(@shades-of-grey, dark) !important;
}

.ant-select-open .ant-select-selection-selected-value {
  color: at(@shades-of-grey, darker) !important;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: at(@shades-of-grey, light) !important;
}
.topTreeNode li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span,
.topTreeNode > span:not(.ant-select-tree-treenode-switcher-open) {
  color: at(@shades-of-grey, darker) !important;
  font-weight: 600 !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;