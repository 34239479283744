.confirmButton {
  margin-left: 10px;
}

.licenseKeyInput {
  height: 60px;
  font-size: 20px !important;
  max-width: 500px;
  min-width: 500px !important;
  text-align: center;
}
.itaNumberInput {
  max-width: 504px;
  min-width: 504px !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;