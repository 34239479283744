.collapsibleRow {
  padding: 0 !important;
  height: 0 !important;
  .collapsibleSectionButton {
    margin-top: -18px;
    &.hide {
      margin-top: -17px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;