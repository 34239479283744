.application-wrapper {
  .application-banner {
    width: 100%;
    height: 100%;
    background: #448ef7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    &.warning {
      background: #ffc43d;
      color: #333;
    }

    &.critical {
      background: #af3947;
    }
  }

  .application-container {
    margin: 16px 24px 24px;
    padding: 0 0 50px;

    .tabs-header {
      display: flex;
      margin-left: 16px;
      padding-top: 8px;

      .tab {
        background: #b0b0b0;
        border: none;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        padding: 0 16px;
        border-radius: 15px 15px 0 0;
        font-size: 16px;
        margin-bottom: -10px;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
          transform: translateY(-5px);
        }

        & + .tab {
          margin-left: 8px;
        }

        &.active {
          background: #af3947;
          transform: translateY(-10px);
        }
      }
    }
  }

  .application-content {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    position: relative;
    z-index: 9;
  }
}

.application-result {
  max-width: 600px !important;
  text-align: center;
}

.application-fileList {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 16px;
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error {
      border-color: #af3947;
      color: #af3947;
    }

    & + li {
      margin-top: 8px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    span.file-name {
      margin-left: 8px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;