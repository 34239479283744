.loading {
  position: absolute;
}
.loadingLogin {
  position: fixed;
}
.loading {
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0.4);
  z-index: 99999;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.loading.hide {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.3s,
    opacity 0.3s ease-out;
}
.loadingWheel {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.85;
}
