.application-start-container {
  h1 {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
  }

  h2 {
    font-size: 18px;
    opacity: 0.7;
    margin-bottom: 24px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;