.dateRangeFilterContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  span.label {
    margin-right: 10px;
    font-weight: bold;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;