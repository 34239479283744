.instructorCalendarContainer {
  .monthlyCalendar {
    height: 950px !important;
  }

  .rbc-calendar {
    width: 100% !important;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;