@import './CommonColors.less';

html,
body,
#root {
  min-height: 100vh !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  // min-width: 800px;
}

button:focus,
input:focus {
  outline: none;
}

input:hover {
  border-color: at(@shades-of-grey, darker) !important;
}

/* Form defaults */

.ant-upload-picture-card-wrapper {
  width: auto !important;
}

.ant-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
}

.ant-modal-title {
  font-weight: 600 !important;
}

.anticon.anticon-close.ant-modal-close-icon > svg {
  color: #fff !important;
}

.ant-form-item {
  margin-bottom: 14px !important;
}

.ant-form-item-label {
  font-weight: 500;
}

.ant-input-number {
  width: 100% !important;
}

.ant-popover {
}
.ant-popover-title {
  background: #eeeded !important;
  border-bottom: 1px solid #dedede !important;
  border-radius: 4px 4px 0 0;
}

.ant-popover-inner-content {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  color: #222 !important;
  z-index: 999;
}
.ant-popover-title {
  color: #222 !important;
  box-shadow:
    0 0px 0 0 rgba(0, 0, 0, 0.2),
    0 0px 10px 0 rgba(0, 0, 0, 0.2) !important;
  z-index: 998;
}

div.ant-steps.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}

div.ant-steps-item.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-steps-item {
  min-width: 200px !important;
  margin-right: 20px !important;
}

.ant-btn:hover {
  opacity: 0.9;
  color: at(@shades-of-grey, darker);
  border-color: at(@shades-of-grey, main) !important;
}

.anticon-arrow-left:hover,
.anticon-arrow-left:active,
.anticon-arrow-left:visited,
.ant-page-header-back-button:active,
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #e56972 !important;
}

input:hover,
input:focus {
  border-color: at(@shades-of-grey, main) !important;
}

input {
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 8px;

  &::-webkit-input-placeholder {
    color: at(@shades-of-grey, light) !important;
  }

  &:-moz-placeholder {
    color: at(@shades-of-grey, light) !important;
  }

  &::-moz-placeholder {
    color: at(@shades-of-grey, light) !important;
  }

  &:-ms-input-placeholder {
    color: at(@shades-of-grey, light) !important;
  }
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden !important;
}

/* .input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
} */

btn,
btn-active,
button,
button:hover,
button:focus,
button:active,
button:visited {
  box-shadow: none !important;
  outline: 0 !important;
}

.noStepper input[type='number']::-webkit-inner-spin-button,
.noStepper input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled {
  opacity: 0.8;
  color: #ccc !important;
}

button:disabled:hover {
  opacity: 0.8;
  color: #ccc !important;
}

.waivesBadge {
  background-color: #f04623;
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 8px;
  font-weight: 600;
  color: white;
  padding: 0px 2px 0 2px;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  transition: all 0.2s ease-in;

  &.pendingApplications:is(.expanded) {
    left: 175px !important;
    top: -2px;
  }

  &.studentsRequests:is(.expanded) {
    left: 175px !important;
    top: -2px;
  }
}

.waivesBadge.expanded {
  left: 145px;
}

.ant-page-header-back {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-page-header-back-button {
  transform: scale(1.8);
}

.ant-menu-item-selected {
  max-width: 200px !important;
}

.white-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);

  & + & {
    margin-top: 32px;
  }

  header {
    padding: 24px;
    border-bottom: 2px solid #eee;

    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .body {
    padding: 24px;
  }
}

.confirmation-modal {
  max-width: 600px !important;
}

.adminSessionsTable {
  td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;