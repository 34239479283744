.application-mt16 {
  margin-top: 16px;
}

.application-mt24 {
  margin-top: 24px;
}

.application-ctas {
  display: flex !important;
  justify-content: center !important;

  button + button {
    margin-left: 16px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;