.price-form {
  background: #fff;
  padding: 20px;
  border-radius: 5px;

  .content {
    display: flex;
    justify-content: space-between;

    > div {
      flex: 1;

      &:first-child {
        max-width: 300px;
        border-right: 1px solid #eee;
      }

      &:last-child {
        padding-left: 45px;
      }

      .org-price-form {
        display: flex;

        button {
          margin-top: 30px;
          margin-left: 10px;
        }
      }
    }

    h2 {
      color: #272727 !important;
      margin-bottom: 20px;
    }

    .promotionsTable {
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
      margin-bottom: 40px;
    }
  }

  footer {
    margin-top: 20px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;