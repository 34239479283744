.questions {
  margin-bottom: 32px;

  &__answer-wrapper {
    > :last-child {
      margin-bottom: 0;
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  }
}

.add-new-question {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 1;
    }

    span {
      margin-left: 12px;
      font-size: 16px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;