@import './CommonColors.less';

// UserCertificationColletionView
div.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);
  border-radius: 4px;

  .card {
    background: #fff;
    width: 500px;
    height: 250px;
    margin: 24px 24px;
    border: 2px solid #eee;
    border-radius: 8px;
    min-height: 150px;
    position: relative;
    box-shadow: 0px 25px 25px -20px rgba(0, 0, 0, 0.5);

    .action-badge {
      border-radius: 3px;
      font-size: 10px;
      font-weight: 600;
      z-index: 5;
      position: absolute;
      color: #fff;
      position: absolute;
      padding: 4px 6px;
      right: -10px;
      top: -10px;
      border: 2px solid #fff;
    }

    .overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, #000000 100%);
      padding: 24px;
      width: 100%;
      height: 100%;
      position: absolute;
      color: #fff;
      display: flex;
      align-items: flex-end;
      border-radius: 8px;
      overflow: hidden;
      opacity: 0.9;
      transition: all 0.2s ease-in;

      &:hover {
        opacity: 1;

        .content {
          transform: translateY(0);
        }
      }

      .content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex: 1;
        transform: translateY(8px);
        transition: all 0.2s ease-in;

        .details {
          display: flex;
          flex-direction: column;

          h1 {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
            margin: 0;
            display: flex;
            align-items: center;

            .ant-tag {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

div.certification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

// End

// CommonCertificationCard

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

div.certification-card {
  position: relative;
  // margin-right: 50px;
  width: 350px;
  padding: 20px 10px 10px 20px;
  border-radius: 15px;
  -webkit-box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  overflow: hidden;

  div.container-body-card {
    margin-top: 20px;
  }

  .container-certification-description {
    line-height: normal;
  }

  div.ant-card-body {
    width: 100% !important;
    height: 100% !important;
  }

  small.current-status {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 500;
  }

  small.expiry-date {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 10px;
  }

  small.certification-number {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 10px;
  }

  div {
    position: relative;
    z-index: 2;

    p {
      color: at(@shades-of-grey, darker);
      line-height: 0.6;
      font-size: 14px;
      transition: 0.5s;
      font-weight: 600;
    }
  }

  button.card-button {
    cursor: pointer;
    visibility: hidden;
    font-size: 18px;
    border: none;
    width: 100%;
    background: at(@shades-of-red, main);
    color: at(@shades-of-white, main);
    margin-bottom: 20px;
    border-radius: 5px !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(-100% + 4px);
    width: 100%;
    height: 100%;
    background: at(@shades-of-grey, main);
    z-index: 1;
    transition: 0.5s ease-in-out;
  }

  &:hover {
    p,
    small {
      color: at(@shades-of-white, main);
    }

    button.card-button {
      visibility: visible;
      animation: 1s linear fade;

      &:hover {
        opacity: 0.8;
      }
    }

    &:before {
      bottom: 0;
    }
  }
}

div.certification-card.started-process-false {
  small.expiry-date {
    visibility: hidden;
  }

  small.certification-number {
    visibility: hidden;
  }
}

div.certification-card.started-process-true {
  small.expiry-date {
    visibility: visible;
  }

  small.certification-number {
    visibility: visible;
  }

  button.card-button {
    cursor: pointer;
    visibility: hidden;
    font-size: 18px;
    border: none;
    width: 100%;
    background: at(@shades-of-grey, main);
    color: at(@shades-of-white, main);
    margin-bottom: 20px;
  }
  button.printCertButton {
    position: absolute;
    top: -8px;
    left: 0;
    padding: 0 0 0 0;
    margin-left: 20px;
    border-width: 0;
    background-color: transparent;
    visibility: hidden;
  }
  .printCertButtonIcon {
    color: white;
    font-size: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(-100% + 4px);
    width: 100%;
    height: 100%;
    background: at(@shades-of-red, main);
    z-index: 1;
    transition: 0.5s ease-in-out;
  }

  &:hover {
    p {
      color: at(@shades-of-white, main);
    }

    button.card-button {
      visibility: visible;
      animation: 1s linear fade;

      &:hover {
        opacity: 0.8;
      }
    }
    button.printCertButton {
      visibility: visible;
      animation: 1s linear fade;
    }

    &:before {
      bottom: 0;
    }
  }
}

// End

.requirementsTitle {
  font-weight: 500;
  line-height: 2;
}

.stepsStartCertificationProcess {
  margin-bottom: 60px;
  box-shadow: '0px -1px 0 0 #e8e8e8 inset';
}

.commonStyleDivContainer {
  margin: 20px 0;
  max-width: 700px;
  line-height: 1.6;
}

#startCertificationProcess > div.ant-row.ant-form-item {
  margin: 0 !important;
}

#timeline {
  margin-top: 50px;
}

.containerCertifications {
  margin-top: 50px;
  padding: 10px;
}

#certificationContent {
  padding-top: -20px;
  background-color: at(@shades-of-grey, lighter) !important;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .header-certification {
    display: inline-block;
    padding: 15px;
    background-color: at(@shades-of-white, main) !important;
    min-height: 100%;
    font-size: 22px;
    border-bottom: 2px solid at(@shades-of-grey, dark) !important;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
    z-index: 1;
  }
}

header.ant-layout-header {
  background-color: #f0f2f5 !important;
}

aside.certificationDetail {
  background: linear-gradient(to bottom, #af3947, #7a0020) !important;
  padding: 10px;
  font-size: 16px;
  line-height: 2;
  color: #f0f2f5;
  border-radius: 8px 0 0 8px;
  min-width: 400px !important;
  max-width: 400px !important;
}

.ant-badge {
  color: #222 !important;
  font-weight: 500;
}

aside.certificationDetail > div.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

aside.certificationDetail > div#detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

aside.certificationDetail > div.ant-layout-sider-children > img {
  margin: 10px 20px;
}

aside.certificationDetail
  > div.ant-layout-sider-children
  > div#detailsContainer
  > div.ant-divider.ant-divider-horizontal.ant-divider-with-text-center
  > span.ant-divider-inner-text,
aside.certificationDetail
  > div.ant-layout-sider-children
  > div#detailsContainer
  > div.ant-divider.ant-divider-horizontal.ant-divider-with-text-left
  > span.ant-divider-inner-text {
  color: #f0f2f5 !important;
}

aside.certificationDetail > div.ant-layout-sider-children > div#detailsContainer > label > strong {
  margin-right: 10px;
}

.badgeContainer {
  display: flex;
}

div.badgeContainer > span {
  margin-right: 10px;
}

div[state] {
  padding: 5px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
}

// Progress, Ready and Waiting
div[state='1'],
div[state='5'],
div[state='0'] {
  background-color: #ffae42 !important;
  color: #fff;
  font-weight: bold;
}
// Completed
div[state='2'] {
  background-color: #8fe388 !important;
}
// Failed
div[state='3'],
div[state='7'] {
  background-color: #e56972 !important;
  color: #fff;
  font-weight: bold;
}
//Cancelled
div[state='4'] {
  background-color: #babcbf !important;
}
//Locked
div[state='6'] {
  background-color: #ffae42 !important;
}
//Button
div.vertical-timeline-element-content.bounce-in
  > div.containterItem
  > h3.vertical-timeline-element-title
  > div
  > button.ant-btn:not(.green) {
  height: 30px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #ffae42 !important;
  color: #ffae42 !important;
  &:hover:enabled {
    background-color: #f7be72 !important;
    color: #fff !important;
  }
}
div.vertical-timeline-element-content.bounce-in
  > div.containterItem
  > h3.vertical-timeline-element-title
  > div
  > .ant-btn-group
  > button.ant-btn:not(.green) {
  height: 30px;
  font-weight: 400;
  border: 1px solid #ffae42 !important;
  color: #ffae42 !important;
  &:hover:enabled {
    background-color: #f7be72 !important;
    color: #fff !important;
  }
}
div.vertical-timeline-element-content.bounce-in
  > div.containterItem
  > h3.vertical-timeline-element-title
  > div
  > button.ant-btn.green {
  height: 30px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid at(@shades-of-green, light) !important;
  color: at(@shades-of-green, main) !important;
  &:hover:enabled {
    background-color: at(@shades-of-green, light) !important;
    color: #fff !important;
  }
}
div.vertical-timeline-element-content.bounce-in
  > div.containterItem
  > h3.vertical-timeline-element-title
  > div
  > button.ant-btn.red {
  height: 30px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid at(@shades-of-red, light) !important;
  color: at(@shades-of-red, main) !important;
  &:hover:enabled {
    background-color: at(@shades-of-red, light) !important;
    color: #fff !important;
  }
}

div.event {
  clip-path: polygon(0% 0%, 100% 0%, 93% 50%, 100% 100%, 0% 100%);
  /* clip-path: polygon(0% 0%, 75% 0%, 93% 50%, 75% 100%, 0% 100%); */
  padding: 15px;
}

div.event {
  justify-content: flex-start;
}

div.event > span.ant-badge {
  color: #fff !important;
}

#timelineVertical {
  background-color: at(@shades-of-grey, lighter) !important;
  padding-bottom: 30px;
}

.special-needs-fixed {
  position: fixed !important;
  background-color: at(@shades-of-red, light) !important;
  color: at(@shades-of-white, main) !important;
  bottom: 80px;
  right: 80px;
  font-size: 16px;
  border: none;
  border-radius: 10%;
  padding: 4px 6px;
  &:hover {
    opacity: 0.8;
  }
}

div.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns {
  margin-bottom: 0 !important;
  padding-bottom: 50px;
}

div.vertical-timeline-element.vertical-timeline-element--no-children > div {
  margin-bottom: 100px;
}

span.vertical-timeline-element-icon.bounce-in {
  display: flex;
  justify-content: center;
}

div.vertical-timeline-element.vertical-timeline-element--no-children
  > div
  > span.vertical-timeline-element-icon.bounce-in,
div.vertical-timeline-element--work.vertical-timeline-element > div > span.vertical-timeline-element-icon.bounce-in {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.vertical-timeline-element.vertical-timeline-element > span.vertical-timeline-element-icon.bounce-in > .anticon {
  height: 100%;
  width: 100%;
}

div.vertical-timeline-element > span.vertical-timeline-element-icon svg {
  height: 100%;
  width: 100%;
  position: relative;
  top: 12px;
  right: 0;
  left: 6px;
  bottom: 0;
}

div.vertical-timeline-element-content.bounce-in {
  padding: 0;
}

div.vertical-timeline-element-content.bounce-in > div.containterItem {
  /* display: flex; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 15px;
}

div.vertical-timeline-element-content.bounce-in > div.containterItem > div > div.badgeContainer > div[state] {
  border-radius: 0;
  height: 30px;
  width: 55%;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: 0;
}

div.containterItem > h3.vertical-timeline-element-title {
  width: 100% !important;
}

.timeline-event-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 682px) {
    flex-direction: column;
    align-items: flex-start;

    .timeline-event-header-title {
      margin-bottom: 10px;
    }
  }
}

.vertical-timeline-element-date {
  display: none !important;
}

.badgeContainer {
  .event {
    @media (max-width: 666px) {
      width: 90% !important;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;