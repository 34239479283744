.card-course {
  border: 2px solid #ececec;
  padding: 24px;
  border-radius: 4px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;

  & + .card-course {
    margin-top: 24px;
  }

  header {
    border-bottom: 2px solid #ececec;
    padding-bottom: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }

    .course-details {
      display: flex;
      align-items: center;

      @media screen and (max-width: 690px) {
        flex-direction: column;

        .course {
          background: #eeeeee66;
          padding: 16px 32px;
          border-radius: 4px;
          margin: 24px 0 24px !important;
          text-align: center;
        }
      }

      .course {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        strong {
          font-size: 20px;
        }

        span {
          font-size: 14px;
          font-weight: bold;

          &.warning {
            color: #ffc43d;
          }

          &.success {
            color: #39af76;
          }

          &.danger {
            color: #af3947;
          }

          &.info {
            color: #03a9f4 !important;
          }
        }
      }
    }

    .menu {
      display: flex;

      @media screen and (max-width: 960px) {
        margin-top: 24px;
      }

      button {
        background: transparent;
        border: 0;
        font-size: 16px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
          background: #eee;
        }

        & + button {
          margin-left: 8px;
        }

        &.active {
          color: #af3947;
          background: #efd7da;
        }
      }

      @media screen and (max-width: 490px) {
        flex-direction: column;
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;

        button {
          width: 100%;
          background: #eeeeeeaa;
          margin: 0 0 8px !important;
          text-align: left;
        }
      }
    }
  }

  .data-card {
    background: #e8e8e8;
    padding: 16px 16px 0;
    border-radius: 4px;

    h2 {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 16px;
    }

    small {
      opacity: 0.8;
    }

    .data-item {
      margin-bottom: 16px;

      .value {
        display: flex;
        align-items: center;
      }
    }
  }
}
// Moved outside to use on other components as result table
.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;

  &.green {
    background: #39af76;
  }

  &.red {
    background: #af3947;
  }
}

.course-card-actions {
  .ant-btn:not(:first-child) {
    margin-left: 20px;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;