//
.rbc-overlay {
  max-width: 600px;
}
//
.dailySessionContainer {
  overflow-x: auto;
  -ms-flex-flow: row nowrap !important;
  flex-flow: row nowrap !important;
  .rbc-calendar {
    width: 100%;
  }
  .rbc-day-slot {
    .rbc-time-slot {
      display: none;
    }
  }
  //
  .rbc-current-time-indicator {
    display: none;
  }
}
//
.weeklySessionContainer {
  overflow-x: auto;
  -ms-flex-flow: row nowrap !important;
  flex-flow: row nowrap !important;
  .rbc-calendar {
    width: 100%;
  }
  .rbc-day-slot {
    .rbc-time-slot {
      display: none;
    }
  }
  //
  .rbc-current-time-indicator {
    display: none;
  }
}
//
.monthlySessionContainer {
  overflow-x: auto;
  -ms-flex-flow: row nowrap !important;
  flex-flow: row nowrap !important;
  height: 70vh;
  .monthlyCalendar {
    height: 100%;
  }
  .rbc-calendar {
    width: 100%;
  }
}

// Events
.cleanEventContainer {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: inline-block;
  flex-direction: row wrap !important;
}
.customSessionEvent {
  box-sizing: border-box;
  box-shadow: none;
  border: 1px #dedede solid;
  margin: 0;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  text-align: left;
  padding: 1px 0 0 5px;
  line-height: 1.3;

  .ant-col {
    position: inherit !important;
  }

  // Monthly
  .titleLabel {
    font-size: 11px;
    color: white;
  }
  // Weekly
  .dateLabel {
    font-size: 11px;
    color: white;
  }
  .secondRow {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .sessionName {
    font-weight: 500;
    font-size: 10px;
    color: white;
    margin-left: 2px;
    margin-top: -1px;
    width: 80%;
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;